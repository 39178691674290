.privacidadTitle {
	font-family: 'Sarabun-SemiBold';
	font-size: 36px;
}

.privacidadDescription {
	font-family: 'Sarabun-Regular';
	font-size: 18px;
	line-height: 25px;
	text-align: left;
}