.carouselHeader {
  font-family: 'Sarabun-SemiBold';
  font-size: 35px;

  @media (max-width: 640px) {
    font-size: 25px;
  }
}

.carouselText {
  font-family: 'Sarabun-Regular';
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.carouselName {
  font-family: 'Sarabun-Bold';
  font-size: 16px;
  line-height: 20px;
}

.carouselIndustry {
  font-family: 'Sarabun-Regular';
  font-size: 14px;
  line-height: 20px;
}

.bgContainer {
  background-color: #f5f5f5;
}


#heroSwiperMobile .swiper-wrapper {
  // padding-bottom: 30px;
}

#heroSwiperMobile,
#heroSwiperMobile * {
  user-select: none;
}

#heroSwiperMobile img {
  pointer-events: none;
}


#heroSwiperMobile .swiper-horizontal>.swiper-pagination-bullets,
#heroSwiperMobile .swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 50px;
  left: 0px;
  width: 100%;
  height: 18px;
  z-index: 10;

  @media (min-width: 414px) {
    bottom: 30px;
  }

  @media (min-width: 1024px) {
    left: -10px;
    bottom: 0px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}

#heroSwiperMobile .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--main-bg-color);

  @media (max-width: 640px) {
    width: 4px;
    height: 4px;
    border-radius: 2px;
  }
}

#heroSwiperMobile .swiper-pagination-bullet-active {
  color: var(--white-color);
  background: #9e9da0;
  width: 35px;
  height: 8px;
  border-radius: 4px;

  @media (max-width: 640px) {
    width: 18px;
    height: 4px;
    border-radius: 2px;
  }
}