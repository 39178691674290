@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Sarabun-Bold';
  src: url('../fonts/sarabun/Sarabun-Bold.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun-ExtraBold';
  src: url('../fonts/sarabun/Sarabun-ExtraBold.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun-Light';
  src: url('../fonts/sarabun/Sarabun-Light.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun-Medium';
  src: url('../fonts/sarabun/Sarabun-Medium.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun-Regular';
  src: url('../fonts/sarabun/Sarabun-Regular.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun-SemiBold';
  src: url('../fonts/sarabun/Sarabun-SemiBold.ttf');
  font-weight: 100;
  font-style: normal;
}

:root {
  --brand-01-color: #0066ff;
  --brand-02-color: #00cba4;
  --brand-03-color: #d2fff5;
}

