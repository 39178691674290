.container {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.thumb {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: var(--brand-01-color);
  display: flex;
  justify-content: center;
  align-items: "center";
}