.bgBackground {
	font-family: 'Sarabun-ExtraBold';
	background: radial-gradient(circle, var(--brand-01-color) 0%, var(--brand-02-color) 0%, var(--brand-01-color) 100%);
	border-radius: 20px;
	height: 581px;
	width: 100%;
	font-size: 48px;
	line-height: 100px;
	color: white;

	@media (max-width: 568px) {
		font-size: 28px;
		line-height: 20px;
		height: 300px;
	}
}

.spaced-text {
	line-height: 1.3;
}


.businessContainer {
	display: inline-flex;
	margin-top: 20px;
	border-bottom-left-radius: 20px;
	border-top-right-radius: 20px;
	transition: transform 0.3s ease-in-out;

	&:hover {
		background: transparent;
		transform: scale(1.07);
	}
}

.business_bt {
	padding: 0px 40px;
	font-family: 'Sarabun-Regular';
	font-size: 20px;
	border: 2px solid white;
	border-bottom-left-radius: 18px;
	border-top-right-radius: 18px;

	&:hover {
		cursor: pointer;
		background: black;
		border-color: black;

	}
}