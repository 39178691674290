
.label {
  flex: 1;
  cursor: pointer;
  font-family: 'Sarabun-Regular';
  font-size: 14px;
  line-height: 20px;
  color: rgba(black, 0.5);
  text-wrap: nowrap;
  letter-spacing: 0;
  padding: 8px 4px;
  
  @media (min-width: 640px) {
    font-family: 'Sarabun-Regular';
    font-size: 16px;
    line-height: 20px;
    padding: 4px 4px;
  }
}

.label:hover {
  background: rgba(black, 0.05);
  border-radius: 5px;
}

.selected {
  color: rgba(black, 0.9) !important;
}
  