.checkBox {
  appearance: none;
  border: 1px solid black;
  background-color: white;
  &:checked {
    border: 1px solid black;
    background-color: white;
    position: relative;
    &:before {
      content: '';
      background-color: transparent;
      /* position the checkbox */
      position: absolute;
      left: 7px;
      top: 2px;

      /* setting the checkbox */
      /* short arm */
      width: 5px;
      border-bottom: 2px solid black;
      /* long arm */
      height: 12px;
      border-right: 2px solid black;

      /* rotate the mirrored L to make it a checkbox */
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  &:focus {
    outline: none;
  }
  vertical-align: top;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}