.continueDescription {
  font-family: 'Sarabun-Light';
  font-size: 16px;
  text-align: start;
}

.continueButtonContainer {
	font-family: 'Sarabun-Regular';
	font-size: 16px;
	width: 176px;
	height: 50px;
	color: white;
	border-radius: 13px;
	padding: 2px;
	background: linear-gradient(45deg, var(--brand-02-color), var(--brand-01-color));
	&:hover {
		padding: 0px;
		background: transparent;
	}
}

.continueButton {
	width: 100%;
	height: 100%;
	color: var(--brand-01-color);
	background: white;
	border-radius: 11px;
	font-family: 'Sarabun-ExtraBold';
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
  &:hover {
		color:white;
    background: linear-gradient(45deg, var(--brand-02-color), var(--brand-01-color));
  }
}