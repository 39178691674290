.HeaderContainer {
  position: sticky;
  top: 0;
  z-index: 50;
  background: white;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Logo {
  color: #0066ff;
  font-size: 5.5rem;
  font-weight: bold;
  text-decoration: none;
}

.Nav {
  font-family: 'Sarabun-SemiBold';
  display: none;
  font-size: 20.5px;

  @media (min-width: 768px) {
    display: flex;
    margin: auto;
    font-family: 'Sarabun-SemiBold';
    gap: 40px;
  }
}

.navLink {
  font-family: 'Sarabun-semiBold';
  padding-right: 55px;
  height: 100px;
  color: black;
  text-decoration: none;
  font-size: 20px;
  transition: color 0.2s;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.navLink {
  &:hover {
    transform: scale(1.07);
  }
}

.registerContainer {
  display: inline-flex;
  width: 310px;
  height: 126px;
  padding: 2px;
  background: linear-gradient(to bottom, #01c2a9, #1270f6);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  overflow: hidden;

  &:hover {
    background: transparent;
  }
}

.registerContainerMobile {
  display: inline-flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 310px;
  height: 126px;
  padding: 2px;
  background: transparent;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  overflow: hidden;
  z-index: 1000;

  &:hover {
    background: transparent;
  }
}

.registerButton {
  font-family: 'Sarabun-SemiBold';
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #0066ff;
  border-bottom-left-radius: 38px;
  border-bottom-right-radius: 38px;
  background: white;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    color: white;
    background: linear-gradient(45deg, #01c2a9, #1270f6);
  }
}


.drop_div {
  background: white;
  color: gray;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);
}

.drop_div_item {
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.07);
  }
}

.dropItemHeader {
  font-family: 'Sarabun-ExtraBold';
  font-size: 20px;
  text-align: left;
  color: var(--brand-01-color);
}

.dropItemBody {
  font-family: 'Sarabun-Medium';
  text-align: left;
  font-size: 13px;
  line-height: 20px;
  color: #6e6d70;
}

.menuBarBg {
  background-image: url('/assets/images/aurora_boreal.jpg');
  background-size: cover;
  /* Ensures the image covers the entire footer */
  background-position: center;
  /* Centers the image */
  background-repeat: no-repeat;
  /* Ensures the image doesn't repeat */
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  color: white;
}

.menuBarFont {
  font-family: 'Sarabun-Bold';
  font-size: 22px;
}