// Accordin Container
.accordinContainer {
  display: inline-flex;
  padding: 2px;
  background: linear-gradient(45deg, #01c2a9, #1270f6);
  border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	border-top-right-radius: 15px;
}

.accordin {
  display: flex;
  width: 100%;
  height: 100%;
  color: black;
  border-bottom-left-radius: 13px;
	border-bottom-right-radius: 13px;
	border-top-right-radius: 13px;
  background: white;
  cursor: pointer;
}

.accordinHeader {
  width: 100%;
  font-family: 'Sarabun-ExtraBold';
  font-size: 36px;
  text-align: start;
  @media (max-width: 945px) {
    font-size: 25px;
    font-family: 'Sarabun-SemiBold';
  }
}

.textTitle {
  font-family: 'Sarabun-SemiBold';
  font-size: 20px;
  @media (max-width: 640px) {
    font-size: 18px;
    font-family: 'Sarabun-ExtraBold';
  }
}

.textDescription {
  font-family: 'Sarabun-Regular';
  font-size: 14px;
  @media (max-width: 640px) {
    font-size: 18px;
    font-family: 'Sarabun-Regular';
  }
}
