.welcome {
  font-family: 'Sarabun-ExtraBold';
  font-size: 27px;
  line-height: 45px;
  color: var(--brand-01-color);
}

.complete {
  font-family: 'Sarabun-Regular';
  font-size: 19px;
  color: black;
}

.general {
  font-family: 'Sarabun-Extrabold';
  font-size: 23px;
  color: var(--brand-01-color);
  cursor: pointer;
}

.information {
  font-family: 'Sarabun-Bold';
  font-size: 16px;
  text-align: start;
}

.informationDescription {
  font-family: 'Sarabun-Light';
  font-size: 16px;
  text-align: start;
}

.selectTypeColor {
  color:#bfbfbf
}