.topicText {
	width: fit-content;
	font-family: 'Sarabun-ExtraBold';
	font-size: 35px;
	line-height: 45px;
	background: linear-gradient(to right, var(--brand-01-color), var(--brand-02-color));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	@media (min-width: 640px) {
		font-size: 50px;
		line-height: 65px;
	}

	@media (min-width: 1280px) {
		font-size: 60px;
		line-height: 80px;
	}
}

.detailText {
	font-family: 'Sarabun-Regular';
	font-size: 16px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.9);

	@media (min-width: 640px) {
		font-size: 20px;
		line-height: 30px;
	}
}

.startBtn {
	width: 220px;
	height: 50px;
	background: black;
	color: white;
	font-family: 'Sarabun-Bold';
	font-size: 18px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: transform 0.3s ease-in-out;

	&:hover {
		background: linear-gradient(45deg, var(--brand-02-color), var(--brand-01-color));
		transform: scale(1.07);
	}
}

.chicaImage {
	width: 140px;
	height: 200px;
	border-top-left-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow: hidden;

	@media (min-width: 640px) {
		width: 170px;
		height: 250px;
	}

	@media (min-width: 1024px) {
		width: 200px;
		height: 300px;
	}
}

.weyTrabajando {
	width: 120px;
	height: 120px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	overflow: hidden;

	@media (min-width: 640px) {
		width: 150px;
		height: 150px;
	}

	@media (min-width: 1024px) {
		width: 190px;
		height: 190px;
	}
}

.cafeteriaImage {
	width: 300px;
	height: 150px;
	border-top-left-radius: 20px;
	border-bottom-right-radius: 20px;
	overflow: hidden;

	@media (min-width: 640px) {
		width: 400px;
		height: 200px;
	}

	@media (min-width: 1024px) {
		width: 600px;
		height: 300px;
	}
}

.middleText {
	padding-left: 0px;
	padding-right: 0px;
	font-family: 'Sarabun-SemiBold';
	font-size: 20px;
	line-height: 30px;
	color: black;

	@media (min-width: 640px) {
		font-size: 28px;
		line-height: 34px;
		padding-left: 40px;
		padding-right: 40px;
	}

	@media (min-width: 1280px) {
		padding-left: 60px;
		padding-right: 60px;
		font-size: 38px;
		line-height: 45px;
	}
}

.greenText {
	color: var(--brand-01-color)
}

.openOfficeImage {
	width: 300px;
	height: 200px;

	@media (min-width: 640px) {
		width: 460px;
		height: 310px;
	}

	@media (min-width: 1280px) {
		width: 780px;
		height: 520px;
	}

	border-radius: 20px;
	overflow: hidden;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
}

.benefitText {
	max-width: 930px;
	font-family: 'Sarabun-SemiBold';
	font-size: 24px;
	line-height: 32px;
	color: black;

	@media (min-width: 640px) {
		font-size: 30px;
		line-height: 30px;
	}

	@media (min-width: 1280px) {
		font-size: 36px;
		line-height: 42px;
	}
}

.benefitTitle {
	font-family: 'Sarabun-SemiBold';
	font-size: 18px;

	@media (min-width: 1280px) {
		font-size: 20px;
	}
}

.MainTitle {
	font-family: 'Sarabun-SemiBold';
	font-size: 18px;

	@media (min-width: 1280px) {
		font-size: 20px;
	}
}

.benefitDesc {
	font-size: 14px;
	max-width: 240px;

	@media (min-width: 640px) {
		font-size: 12px;
		max-width: 154px;
	}

	font-family: 'Sarabun-Regular';
	color: rgba(0, 0, 0, 0.8);

	@media (min-width: 1280px) {
		font-size: 14px;
	}
}

.businessTip {
	width: 380px;
	height: 100px;
	padding: 20px;
	border-bottom-left-radius: 20px;
	border-top-right-radius: 20px;
	background: white;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);
}

.obtainTip {
	width: 380px;
	height: 100px;
	padding: 20px;
	border-bottom-right-radius: 20px;
	border-top-left-radius: 20px;
	background: white;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);
}

.businessTitle {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.8);
}

.businessText {
	font-family: 'Sarabun-Medium';
	font-size: 20px;
	color: black;
}

.businessGreen {
	color: var(--brand-02-color);
}

.obtainText {
	font-family: 'Sarabun-Medium';
	font-size: 17px;
	color: rgba(0, 0, 0, 0.9)
}

.obtainBlue {
	color: var(--brand-01-color);
}