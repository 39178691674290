

.inputBox {
  width: 100%;
  height: 40px;
  padding-left: 12px;
  background: white;
  border-radius: 10px;
  border-width: 1px;
  font-family: 'Sarabun-Regular';
  font-size: 17px;
  color: rgba(0,0,0, 0.8);
  &:focus {
    outline: none;
  }
  &:hover {
    background: white;
  }
}

.inputBox::placeholder {
  font-family: 'Sarabun-Regular';
  font-size: 16px;
  color: rgba(0,0,0, 0.8)
}

.emptyLabel {
  color: #bd2f27;
 	font-family: 'Sarabun-Regular';
  font-size: 13px;
  text-align: start;
  margin-left: 10px;
}

.infoLabel {
  color: rgba(0,0,0, 0.6);
 	font-family: 'Sarabun-Regular';
  font-size: 13px;
  text-align: end;
  margin-left: 10px;
  margin-right: 10px;
}


