.financialText {
	font-family: 'Sarabun-Bold';
	font-size: 28px;
	line-height: 30px;
	color: white;

	@media (min-width: 768px) {
		font-size: 30px;
		line-height: 30px;
	}

}

.quoteText {
	font-family: 'Sarabun-Regular';
	font-size: 16px;
	color: white;
	text-align: start;

	@media (min-width: 640px) {
		font-size: 20px;
	}
}

.startTopbar {
	width: 100%;
	height: 70px;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	background: var(--brand-02-color);
	color: white;
	font-family: 'Sarabun-Bold';
	font-size: 18px;
	display: flex;
	align-items: center;
	padding-left: 40px;

	@media (min-width: 640px) {
		font-size: 21px;
	}

	@media (min-width: 1280px) {
		width: 530px;
	}
}

.startMain {
	background: white;
	border-bottom-left-radius: 30px;

	@media (min-width: 1280px) {
		border-top-right-radius: 30px;
	}

	overflow: hidden;
}

.howMuch {
	font-family: 'Sarabun-SemiBold';
	font-size: 18px;
	color: black;
	text-align: start;

	@media (min-width: 1024px) {
		font-size: 21px;
	}
}

.priceArea {
	width: 200px;
	height: 45px;
	background: var(--brand-03-color);
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Sarabun-SemiBold';
	font-size: 18px;
	color: black;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.priceRightBG {
	background: var(--brand-03-color);
}

.priceRightTitle {
	font-family: 'Sarabun-Light';
	font-size: 18px;
	color: black;

	@media (min-width: 640px) {
		font-size: 20px;
	}

	@media (min-width: 1024px) {
		font-size: 22px;
	}
}

.priceRightValue {
	font-family: 'Sarabun-ExtraBold';
	font-size: 24px;
	line-height: 30px;
	color: var(--brand-01-color);

	@media (min-width: 640px) {
		font-size: 30px;
		line-height: 36px;
	}
}

.priceDesc {
	font-family: 'Sarabun-SemiBold';
	font-size: 13px;
	line-height: 18px;

	@media (min-width: 768px) {
		font-size: 14px;
		line-height: 20px;
	}
}

.overflow {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Black with 50% transparency */
}

.containerBtn {
	width: 100%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	background: linear-gradient(to right, var(--brand-02-color), var(--brand-01-color));
	border-radius: 10px;
	overflow: hidden;
	font-family: 'Sarabun-Medium';
	font-size: 14px;
	color: white;
	cursor: pointer;

	@media (min-width: 640px) {
		font-size: 16px;
	}
}

.buttonPrice {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	border-radius: 8px;
	justify-content: center;
	color: black;
	background: white;
}

.creditBtn {
	width: 220px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;
	color: white;
	font-family: 'Sarabun-semiBold';
	font-size: 18px;
	border-bottom-left-radius: 20px;
	border-top-right-radius: 20px;
	transition: transform 0.3s ease-in-out;

	&:hover {
		background: linear-gradient(to right, #01c2a9, #1270f6);
		color: white;
		transform: scale(1.07);
	}

	@media (min-width: 640px) {
		font-size: 18px;
	}
}