.title {
	font-family: 'Sarabun-Bold';
	font-size: 24px;
	line-height: 45px;
	color: rgba(0,0,0, 1);
  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 45px;
  }
}

.content {
  width: 100%;
	font-family: 'Sarabun-Regular';
	font-size: 18px;
	line-height: 24px;
  letter-spacing: 1px;
	color: rgba(0,0,0,0.9);
  padding-left: 40px;
  padding-right: 40px;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;

  @media (min-width: 1024px) {
    width: 960px;
    font-size: 17px;
    line-height: 24px;
    margin-top: 30px;
  }

  @media (min-width: 1280px) {
    width: 1100px;
    font-size: 17px;
    line-height: 24px;
    margin-top: 30px;
  }
}