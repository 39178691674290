.businessDropDown {
  width: 100%;
  height: 40px;
	border-radius: 10px;
  border: 1px solid black;
  font-family: 'Sarabun-Regular';
  font-size: 18px;
  color: rgba(0,0,0, 1);
	padding: 5px 15px;
  &:focus {
    outline: none;
  }
}

.businessShadow {
  font-family: 'Sarabun-Regular';
	display: inline-flex;
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  font-size: 18px;
  color: rgba(0,0,0, 1);
	background: white;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 10, 0.19);
  &:focus {
    outline: none;
  }
}

.businessBoxShadow {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 10, 0.19);
	background: transparent;
}