.radio {
  appearance: none;
  border: 2px solid black;
  background-color: transparent;
  vertical-align: top;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 12px;

  &:checked {
    border: 2px solid var(--brand-01-color);
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: var(--brand-01-color);
    }
  }

  &:focus {
    outline: none;
  }
}

.lightRadio {
  appearance: none;
  border: 2px solid rgba(var(--background-dark-color), 0.6);
  background-color: transparent;
  vertical-align: top;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;

  &:checked {
    border: 2px solid var(--background-dark-color);
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: var(--background-dark-color);
    }
  }

  &:focus {
    outline: none;
  }
}

.label {
  flex: 1;
  cursor: pointer;
  font-family: 'Sarabun-Bold';
  font-size: 16px;

  @media (min-width: 1024px) {
    font-size: 20px;
  }

  color: black;
  text-align: start;
  text-wrap: nowrap;
  letter-spacing: 0;
}

.selected {
  color: var(--brand-01-color) !important;
}

.tooltip {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(black, 0.1);
  }
}