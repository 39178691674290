.btnArea {
  width: 100% !important;
  @media (min-width: 1024px) {
    width: 100% !important;
    font-size: 16px;
    gap: 7px;
    padding: 13px 15px;
  }
  height: 40px;
  padding: 13px 10px;
  font-family: 'Sarabun-Regular';
  font-size: 16px;
  color: rgba(0,0,0, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.7);
  background: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 7px;
}

.menuArea {
  position: absolute;
  width: 100% !important;
  max-height: 200px;
  @media (min-width: 1024px) {
    width: 100% !important;
    max-width: 600px;
    top: 45px;
    padding: 10px 15px;
  }
  background: white;
  top: 47px;
  padding: 6px 8px;
  
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 10px;

  overflow: auto;
  cursor: pointer;
  z-index: 100;
  outline: none;
}

.menuItem {
  width: 100%;
  transition: all 0.2s;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
}

.removeBlueHighlight {
  * {
    -webkit-tap-highlight-color: transparent !important;
    outline: none !important;
  }
}
