.mainBgImage {
	background-image: url('/assets/images/aurora_boreal.jpg');
	background-size: cover; /* Ensures the image covers the entire footer */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Ensures the image doesn't repeat */
	overflow-y: hidden;
  overflow-x: hidden;
	width: 100%;
}

.signinHeader {
	font-family: 'Sarabun-ExtraBold';
	font-size: 18px;
	color: white;
}

.signinDescription {
	font-family: 'Sarabun-Light';
	font-size: 15px;
	color: white;
}

.signinContainer {
	display: inline-flex;
  width: 145px;
  height: 42px;
  padding: 2px;
	border: 1.5px solid white;
  background: transparent;
  border-radius: 10px;
  overflow:hidden;
	color: white;
	justify-content: center;
	align-items: center;
}