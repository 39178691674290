// Logo styles
.logo {
  color: #0066FF;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

// Footer styles
.footer {
  background-image: url('/assets/images/estrellas.png');
  background-size: cover;
  /* Ensures the image covers the entire footer */
  background-position: center;
  /* Centers the image */
  background-repeat: no-repeat;
  /* Ensures the image doesn't repeat */
  overflow-y: hidden;
  overflow-x: hidden;
  color: white;
  width: 100%;
  height: auto;

  @media (min-width: 1280px) {
    height: auto;
  }
}

// FooterContent styles
.footerContent {
  width: 100%;
  height: auto;

  @media (min-width: 1280px) {
    height: auto;
  }

  display: flex;
  flex-direction: row;

  @media (min-width: 768px) {
    padding: 45px 20px 0px 20px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: start;
  }
}

.maskBG {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black with 50% transparency */
}

// SocialLinks styles
.social-links {
  display: flex;
  gap: 1.5rem;
}

// SocialLink styles
.social-link {
  color: white;
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.2s;

  &:hover {
    color: #0066FF;
  }
}

.registerButton_1 {
  font-family: 'Sarabun-Bold';
  display: flex;
  width: 400px;
  height: 137px;
  align-items: center;
  justify-content: center;
  color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background: linear-gradient(45deg, var(--brand-01-color), var(--brand-02-color), );
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: white;
    background: linear-gradient(45deg, var(--brand-02-color), var(--brand-01-color));
  }
}

.footerTextTitle {
  font-family: 'Sarabun-bold';
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}

.footerTextBody {
  font-family: 'Sarabun-semiBold';
  font-size: 11px;
  line-height: 16px;
  text-align: left;
}